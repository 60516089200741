import * as Sentry from "@sentry/nuxt";
const { public: env } = useRuntimeConfig();

Sentry.init({
  dsn: env.SENTRY_DSN,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    // floating widget integration
    // Sentry.feedbackIntegration({
    //   // Additional SDK configuration goes in here, for example:
    //   colorScheme: "system",
    // }),
    // normal replay integration
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    //   canvas integration to record certain sections only
    Sentry.replayCanvasIntegration({
      enableManualSnapshot: true,
    }),
    Sentry.captureConsoleIntegration(),
  ],
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && event.event_id) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});
